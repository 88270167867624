import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Button, Icon, Breadcrumb } from "semantic-ui-react";
import AppContainer from "../containers/AppContainer";
import subscribe from "unstated-subscribe-hoc";

class CardView extends Component {
  render() {
    const { removeCard, saveCard } = this.props.appStore;
    const { data, history } = this.props;
    // generate doc hierarchy
		const meta = 
		['set','fileName','hat','pocket','block']
		.map( key =>
			({ key, content: this.props.data[key], link: false })
		)
		.filter(item => item.content)


    // ['set','fileName','hat','pocket','block']
    // .map(key => this.props.data[key])
    // .filter(Boolean)
    // .join(' > ');
    const buttonStyle = { margin: "5px 0 5px 0" };
    return (
      <Card fluid className="card card-view">
        <Card.Content>
          <Card.Header> {data.tag} </Card.Header>
          <Card.Description className="card-cite">{data.cite}</Card.Description>
          <Card.Meta>
            <Breadcrumb icon="right angle" sections={meta} />
          </Card.Meta>
        </Card.Content>
        {!this.props.simple && (
          <Card.Content extra>
            <div className="ui  buttons">
              <Button
                style={buttonStyle}
                onClick={() => saveCard(data)}
                icon="arrow right"
                color="blue"
              />
            </div>
            <div className="ui  buttons">
              <Button
                style={buttonStyle}
                onClick={() => history.push(`/card/${data._id}`)}
                icon="eye"
                active
                basic
                color="blue"
              />
            </div>
          </Card.Content>
        )}
        {this.props.simple && (
          <Card.Content extra>
            <div className="ui  buttons">
              <Button
                style={buttonStyle}
                onClick={() => removeCard(data._id)}
                icon="remove"
                basic
                color="red"
              />
            </div>
            <div className="ui  buttons">
              <Button
                style={buttonStyle}
                onClick={() => history.push(`/card/${data._id}`)}
                icon="eye"
                active
                basic
                color="blue"
              />
            </div>
          </Card.Content>
        )}
      </Card>
    );
  }
}

export default subscribe(withRouter(CardView), { appStore: AppContainer });
