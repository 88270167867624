import React, {Component} from 'react';
import CardView from './Card'
import SearchForm from './SearchForm'
import AppContainer from '../containers/AppContainer';
import subscribe from 'unstated-subscribe-hoc'
import { Card, Header, } from 'semantic-ui-react'

class SearchPane extends Component {

	saveCard = card => {
		this.props.setSaved([...this.props.saved, card]) 
	}

	searchResults = (data) => (
		<Card.Group style={{padding:'5px', paddingLeft:'1px'}}>
			{
				data.map((item, index) =>
					<CardView key={item._id} data={item}/>
				)
			}
		</Card.Group>
	)
	
	render() {
		const {search} = this.props.appStore.state;
		return (
			<div className="SearchPane">
				<Header as='h3' dividing>Search</Header>
					<div style={{overflowX:'hidden',height:this.props.height-200}} className="scroll-box">
						<SearchForm />
						{/* <Route path="/search" component={() => this.searchResults(search)} /> */}
						{this.searchResults(search)}
					</div>
			</div>
		);
	}
}

export default subscribe(SearchPane, { appStore: AppContainer });

